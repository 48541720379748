import React from "react";
import "./TimeTable.scss";
import TimeTableCard from "./TimeTableCard/TimeTableCard";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";

class SwitchButton extends React.Component {
	constructor(props) {
		super(props);
		this.handleclick = this.handleclick.bind(this);
	}

	handleclick() {
		this.props.handleclick();
	}
	render() {
		let classname = "timeTable-switchButton";
		if (this.props.active) {
			classname = classname + " timeTable-buttonActive text-sky-blue";
		} else {
			classname = classname + " text-yellow";
		}

		return (
			<button className={classname} onClick={this.handleclick}>
				{this.props.name}
			</button>
		);
	}
}

class TimeTableSwitcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			position: "",
			listRef: React.createRef()
		};
	}

	switchPosition(newPosition) {
		console.log(newPosition, this.props.dayRefs, this.state.listRef);

		this.state.listRef.current.scroll({
			top: 0,
			left: this.props.dayRefs[newPosition].current.offsetLeft,
			behavior: "smooth"
		});
		// var slides = this.state.listRef.current;
		// var scroll = this.props.dayRefs[newPosition].current.offsetLeft;
		// var interval = setInterval(function () {
		// 	// console.log(slides.scrollLeft, slides.scrollWidth, scroll);
		// 	console.log(slides.scrollWidth, slides.scrollLeft, slides.clientWidth);
		//
		// 	if (
		// 		slides.scrollLeft === scroll ||
		// 		scroll === slides.scrollLeft + slides.clientWidth
		// 	) {
		// 		clearInterval(interval);
		// 	} else {
		// 		console.log(Math.abs(slides.scrollLeft), scroll);
		// 		if (Math.abs(slides.scrollLeft - scroll) < scroll / 10)
		// 			slides.scrollLeft = scroll;
		// 		else {
		// 			slides.scrollLeft += (scroll - slides.scrollLeft) / 10;
		// 			console.log(
		// 				"moving!",
		// 				(scroll - slides.scrollLeft) / 10,
		// 				slides.scrollLeft
		// 			);
		// 		}
		// 	}
		// }, 10);
	}

	render() {
		const buttons = ["20. Juli", "21. Juli", "22. Juli", "23. Juli"];
		return (
			<div className={"timeTableSwitcher"}>
				<div className="timeTable-buttonList">
					{buttons.map((buttonName, index) => (
						<SwitchButton
							handleclick={() => this.switchPosition(index)}
							name={buttonName}
							active={this.state.position === index}
						/>
					))}
				</div>
				<div ref={this.state.listRef} className={"timeTableList "}>
					{Array.isArray(this.props.tables)
						? this.props.tables.map((table, index) => (
								<div ref={this.props.dayRefs[index]}>
									<TimeTableCard
										date={table.date}
										events={table.events}
										setVideo={this.props.setVideo}
										/>
								</div>
						  ))
						: null}
				</div>
			</div>
		);
	}
}

class TimeTable extends React.Component {
	constructor(props) {
		super(props);
		this.dayRefs = [React.createRef(), React.createRef(), React.createRef(), React.createRef()];
	}

	render() {
		return (
			<div class="programm">
				<Grid item xs={12}>
					<TimeTableSwitcher
						setVideo={this.props.setVideo}
						tables={this.props.tables}
						dayRefs={this.dayRefs}
					/>
				</Grid>
			</div>
		);
	}
}

export default withRouter(TimeTable);
