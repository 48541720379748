import React from 'react'
import './ImageGallery.scss'

const ImageGallery = (props) => {
	return (
		<div className="imageGallery_container">
			{Array.isArray(props.images)
				? props.images.map((image) => (
						<img
							src={`/strapiExport${image.url}`}
							alt={`/strapiExport${image.alternativeText}`}
						/>
				  ))
				: null}
		</div>
	)
}

export default ImageGallery
