import axios from 'axios'
import projects from '../strapiExport/projekts.json'

export const findAllProjects: any = async () => {
	return projects
}

export const findProjectById: any = async (id: string) => {
	return [findObjectByField(projects, '_id', id)]
}

function findObjectByField(array: any, field: string, value: string) {
	for (let i = 0; i < array.length; i++) {
		if (array[i][field] === value) {
			return array[i]
		}
	}
}
