import React from 'react'
import './Chat.scss'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { useEffect } from 'react'
import EmojiGrid from './Emotes/emotes'
import TextareaAutosize from 'react-textarea-autosize'
import Send from '../../assets/png/send4x.png'

class Chat extends React.Component {
	constructor(props) {
		let date = new Date()
		let d =
			date.getHours().toString().padStart(2, 0) +
			':' +
			date.getMinutes().toString().padStart(2, 0)

		super(props)

		this.chatMessageDisplay = React.createRef()

		this.state = {
			messageList: [
				{
					userName: 'IF',
					timestamp: d,
					text: 'Gib als erstes deinen Namen ein unter dem du Nachrichten senden möchtest',
				},
			],
			timestamp: d,
			isLoggedIn: false,
			currentMessage: '',
			phaserKey: '',
			placeholderTest: 'Dein Name',
		}
		this.socket = props.socket
		this.phaserSocket = props.phaserSocket
		this.errorList = {
			100: 'Der Name ist leider schon vergeben.',
			101: 'Der Name darf keine Emojies beinhalten.',
			102: 'Der Name darf maximal 15 Zeichen lang sein.',
			103: 'Der Name darf keine Zeilenumbrüche beinhalten',
			104: 'Der Name darf nicht leer sein. Hinweis: Leerzeichen werden vom Server entfernt.',
			200: 'Spam ist nicht erlaubt.',
		}
		this.socket.on('msg', (data) => {
			this.addMessage(data)
		})
		this.socket.on('connectionAllowed', (data) => {
			let d =
				date.getHours().toString().padStart(2, 0) +
				':' +
				date.getMinutes().toString().padStart(2, 0)
			this.addMessage({
				userName: 'IF',
				timestamp: d,
				text: 'Herzlich Willkommen ' + data.name + '!',
			})
			this.setState({ phaserKey: data.phaserKey })
			console.log(window.frames)
			document
				.getElementById('phaserIframeWindow')
				.contentWindow.postMessage(
					{ message: 'phaserKey', value: data.phaserKey },
					'*'
				)
			var phaserKey = phaserKey
			this.setState({ isLoggedIn: true })
			this.setState({ placeholderTest: 'max. 200 characters' })
		})
		this.socket.on('disconnect', () => {
			let d =
				date.getHours().toString().padStart(2, 0) +
				':' +
				date.getMinutes().toString().padStart(2, 0)
			this.addMessage({
				userName: 'IF',
				timestamp: d,
				text: 'Sie sind gerade Disconnected. Dies kann entweder an ihrer Verbindung liegen oder unser Technikteam startet gerade die Server neu. Bitte geben sie wieder ihren Namen ein, um sich einzuloggen.',
			})
			this.setState({ isLoggedIn: false })
			this.setState({ placeholderTest: 'Dein Name' })
		})
		this.socket.on('chatError', (data) => {
			let d =
				date.getHours().toString().padStart(2, 0) +
				':' +
				date.getMinutes().toString().padStart(2, 0)
			this.addMessage({
				userName: 'IF',
				timestamp: d,
				text: this.errorList[data.error],
			})
			if (data.error[0] == '1') {
				this.setState({ isLoggedIn: false })
			}
		})
		this.addMessage = this.addMessage.bind(this)
		this.sendMessage = this.sendMessage.bind(this)
		this.handelChatInputChange = this.handelChatInputChange.bind(this)
		this.inputFieldFokused = this.inputFieldFokused.bind(this)
		this.onKeyDown = this.onKeyDown.bind(this)
		this.addEmoteToMessage = this.addEmoteToMessage.bind(this)
	}

	handelChatInputChange(event) {
		this.setState({ currentMessage: event.target.value })
	}

	componentDidMount() {
		this.socket.connect()
		this.phaserSocket.connect()
	}
	componentWillUnmount() {
		this.socket.disconnect()
		this.phaserSocket.disconnect()
		this.setState({ isLoggedIn: false })
		this.setState({ placeholderTest: 'Dein Name' })
	}

	addMessage(message) {
		this.setState(
			{
				messageList: [...this.state.messageList, message],
			},
			() => {
				const chatMessageHeight = this.chatMessageDisplay.current.scrollHeight
				this.chatMessageDisplay.current.scrollTo(0, chatMessageHeight)
			}
		)
	}

	inputFieldFokused() {
		this.props.onInputClick()
	}

	sendMessage(e) {
		if (e) {
			e.preventDefault()
		}
		if (this.state.currentMessage != '') {
			if (this.state.isLoggedIn) {
				this.socket.emit('msg', {
					timestamp:
						new Date().getHours().toString().padStart(2, 0) +
						':' +
						new Date().getMinutes().toString().padStart(2, 0),
					text: this.state.currentMessage,
				})
			} else {
				this.socket.emit('requestConnection', {
					name: this.state.currentMessage,
				})
			}
		}
		this.setState({ currentMessage: '' })
	}

	addEmoteToMessage(ligatur) {
		// console.log(this.props.lastFokusInputField)
		if (this.props.lastFokusInputField) {
			this.setState({ currentMessage: this.state.currentMessage + ligatur })
		} else {
			this.phaserSocket.emit('onePlayerEmotedFromReact', {
				ligatur: ligatur,
				phaserKey: this.state.phaserKey,
			})
		}
	}

	onKeyDown(e) {
		if (e.keyCode === 13 && !e.shiftKey) {
			this.sendMessage()
			e.preventDefault()
		}
	}

	render() {
		let emojiList = [
			'smile',
			'sad',
			'angry',
			'amazed',
			'clap',
			'demon',
			'lol',
			'sour',
			'heart',
			'thumb',
		]
		let buildMessageHtml = (message) => {
			let emojiSplittedMessage = message.split(':')
			let lineWasEmoji = false
			return emojiSplittedMessage.map((line, index) => {
				if (emojiList.includes(line)) {
					lineWasEmoji = true
					return <span className={line + ' emoji'}></span>
				}
				if (line) {
					if (lineWasEmoji) {
						lineWasEmoji = false
						return <span>{line}</span>
					} else {
						if (index === 0) {
							return <span>{line}</span>
						}
						return <span>:{line}</span>
					}
				}
			})
		}
		let chatMessages = this.state.messageList.map((message) => {
			if (message.userName) {
				let splittedText = buildMessageHtml(message.text)
				return (
					<p className="chat-message a">
						{' '}
						<strong>{message.timestamp} </strong> {message.userName + ': '}
						{splittedText}
					</p>
				)
			}
		})
		return (
			//BITTEE RICHTIGE CSS KLASSEN MACHEN :=)
			<div class="chat">
				<div
					className="text-nearly-black chat-message-display"
					ref={this.chatMessageDisplay}
				>
					{chatMessages}
				</div>

				<form
					onSubmit={this.sendMessages}
					className="message-input-form"
					id="chatForm"
				>
					<div className="message-input">
						<TextareaAutosize
							className="text-navi-blue text-small"
							maxLength="200"
							value={this.state.currentMessage}
							onChange={this.handelChatInputChange}
							placeholder={this.state.placeholderTest}
							type="text"
							onKeyDown={this.onKeyDown}
							id="chatInpuFieldID"
							// onKeyDown={this.sendMessage}
							onFocus={this.inputFieldFokused}
						/>

						<button
							onClick={this.sendMessage}
							className="submit-button"
							type="submit"
						>
							<img width="27px" height="27px" src={Send} />
						</button>
					</div>
					{<EmojiGrid addEmoteToMessage={this.addEmoteToMessage} />}
				</form>
			</div>
		)
	}
}

export default withRouter(Chat)
