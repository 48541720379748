import React from 'react'
import { useHistory } from 'react-router-dom'
import './FlipCard.scss'

const FlipCard = (props) => {
	const history = useHistory()
	const flipCard = (e) => {
		const parentCard = e.currentTarget
		//const isFlipped = parentCard.classList.hasClass("flipped")
		unFlipAll(parentCard)
		parentCard.classList.toggle('flipped')
	}

	const unFlipAll = (activeCard) => {
		const flipCards = document.getElementsByClassName('flip-card')
		Array.from(flipCards).forEach((flipCard) => {
			if (flipCard !== activeCard) {
				flipCard.classList.remove('flipped')
			}
		})
	}
	const handleProjectLinkClick = (e, id) => {
		e.preventDefault()
		history.push('/' + id)
	}

	return (
		<div
			className={'flip-card row-small ' + props.positionClass + props.rowClass}
			onClick={(e) => flipCard(e)}
		>
			<div className={'flip-card-inner '}>
				<div
					className={'flip-card-front'}
					style={{
						backgroundImage: `url(/strapiExport${props.thumbnailUrl})`,
					}}
				></div>
				<div className={'flip-card-back'}>
					{props.title ? <p className={'h2'}>{props.title}</p> : null}
					<p>{props.description}</p>
					<button
						className="small"
						onClick={(e) => handleProjectLinkClick(e, props.projectId)}
					>
						Mehr erfahren
					</button>
				</div>
			</div>
		</div>
	)
}

export default FlipCard
