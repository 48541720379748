import React, { useEffect } from 'react'
import './Footer.scss'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import iLoveIMD from 'assets/svg/iloveimd.svg'
import instagramLogo from 'assets/svg/instagram.svg'
import mastodonLogo from 'assets/svg/mastodon.svg'
import supportLove from 'assets/svg/supporter_danke.svg'
import * as LogoService from 'services/LogoService.tsx'

// export const app = () => (
// 	<div>
// 	  {/* Logo is an actual React component */}
// 	  <iLoveIMD fill="red"/>
// 	</div>
//   );

export const SocialFoot = (props) => {
	return (
		<>
			<a
				href="https://imd.mediencampus.h-da.de/"
				target="_blank"
				rel="noopener noreferrer"
				className="footer_real_social_link picture"
			>
				<img src={iLoveIMD} alt="IMD Logo: I love IMD"></img>
			</a>
			<a
				href="https://www.instagram.com/interactivemediadesign.hda"
				target="_blank"
				rel="noopener noreferrer"
				className="footer_real_social_link"
			>
				<img src={instagramLogo} alt="Instagram Logo"></img>
				<span>Instagram</span>
			</a>
			<a
				href="https://imd.social/@imd"
				target="_blank"
				rel="noopener noreferrer"
				className="footer_real_social_link"
			>
				<img src={mastodonLogo} alt="Mastodon Logo"></img>
				<span>Mastodon</span>
			</a>
		</>
	)
}

const Footer = () => {
	const [fetchData, setFetchData] = React.useState([])

	const getData = async () => {
		const res = await LogoService.findAllLogos()
		setFetchData(res)
	}

	useEffect(() => {
		getData()
	}, [])

	const fetchedLogos = fetchData?.map((logo) => {
		let sizeDiscriminator = ''
		logo.isBig === true
			? (sizeDiscriminator = 'bigLogo')
			: (sizeDiscriminator = '')
		const logoUrl = (logo.Logos[0] || {}).url
		return (
			<Grid
				className={'footer_sponsors-content_sponsor big'}
				item
				xs={12}
				sm={6}
				md={4}
			>
				<img
					src={`/strapiExport${logoUrl}`}
					className={sizeDiscriminator}
					alt={logo.altText}
					title={logo.title}
				/>
			</Grid>
		)
	})

	return (
		<footer className="footer">
			<Grid container spacing={0}>
				<Grid item xs={10} className="footer_support-container">
					<div className="footer_sponsors-heading">
						<img src={supportLove} alt="supporter love"></img>
					</div>
					<Grid className="footer_sponsors-content" container>
						{fetchedLogos}
					</Grid>
				</Grid>
				<Grid className="footer_real" container spacing={1}>
					<Grid className="footer_real_information" item xs={12} md={6}>
						<Grid item xs={1}></Grid>
						<Link to="/legal-notice" className="footer_real_information_site">
							Impressum
						</Link>
						<Link to="/contact" className="footer_real_information_site">
							Kontakt
						</Link>
						<Link to="/privacy" className="footer_real_information_site">
							Datenschutz
						</Link>
						<Grid item xs={1}></Grid>
					</Grid>

					<Grid
						className={'footer_real_social'}
						item
						xs={12}
						md={6}
						spacing={0}
					>
						<Grid item xs={1}></Grid>
						<SocialFoot />
						<Grid item xs={1}></Grid>
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
			</Grid>
		</footer>
	)
}

export default Footer
