import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'
import * as SpeakerService from 'services/SpeakerService.tsx'
import MultiTopic from 'components/MultiTopic/MultiTopic'
import HeroSpace from 'components/global/HeroSpace/HeroSpace'

//Bilder
import speakerImage from 'assets/svg/herospace/speaker_header.svg'
import msPc from 'assets/png/speaker/ms-pc.png'
import qundg from 'assets/png/speaker/qundg.png'
import simoneRebaudengo from 'assets/png/speaker/simone-rebaudengo.png'
import xeem from 'assets/png/speaker/xeem.png'
import gSjK from 'assets/png/speaker/gs-jk.png'

const images = [simoneRebaudengo,xeem,qundg,msPc,gSjK]

const Speakers = () => {
	const [fetchSpeaker, setFetchSpeaker] = React.useState([])

	const getSpeaker = async () => {
		const res = await SpeakerService.findAllSpeakers();
		const speaker = []
		res.forEach(e => {
			const sp =
			{
				 id: e.speakerID,
				 heading: e.speakerName,
				 paragraph: e.speakerDescription,
				 image: images[res.indexOf(e)],
				 imageLeft: e.imageLeft
			
		}
		speaker.push(sp);
	});
		setFetchSpeaker(speaker)
	}
	
	useEffect(() => {
		window.scrollTo(0, 0)
		getSpeaker();
	}, [])
	return (
		<Container>
			<HeroSpace
				h1="speaker"
				subline="line"
				year="2021"
				noTextBg
				image={speakerImage}
			/>

			{
				<MultiTopic
					topics={fetchSpeaker} 
					alternateBackgroundColor="true"
					
				/>
			}
		</Container>
		
		
	)
}

export default Speakers