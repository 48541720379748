import React from 'react'
import '../VideoGallery.scss'

const VideoCard = (props) => {
	return (
		<div className="videoGallery_container_card_container">
			<div className="videoGallery_container_video-card" to={props.url}>
				<h4>{props.title} - Mach dir selbst ein Bild!</h4>
				<div className="videoGallery_container_frame">
					<iframe
						src={`https://player.vimeo.com/video/${props.src}`}
						frameborder="0"
						webkitallowfullscreen=""
						mozallowfullscreen=""
						allowfullscreen=""
						autopause="true"
						dnt="true"
						title="true"
					/>
					{/*
          <div className='videoGallery_container_videoCard_thumbnail' style={{backgroundImage: 'url('+props.thumbnail+')'}}>
            <div className='videoGallery_container_videoCard_thumbnail_playButton'></div>
          </div>
          */}
				</div>
			</div>
		</div>
	)
}

export default VideoCard
