import React from 'react'
import HeroSpace from 'components/global/HeroSpace/HeroSpace'
import TimeTableCard from 'components/TimeTable/TimeTableCard/TimeTableCard'
import { Grid, Container } from '@material-ui/core'
import './Program.scss'
import ProgramData from 'assets/program.json'

//Bilder
import startPic from 'assets/svg/heart.svg'
import wednesday from 'assets/svg/heart.svg'
import thursday from 'assets/svg/heart.svg'
import friday from 'assets/svg/heart.svg'

import desktop from 'assets/svg/heart.svg'
import emoji from 'assets/svg/heart.svg'
import TimeTable from 'components/TimeTable/TimeTable'

class Program extends React.Component {
	state = {
		currentVideo: 'IMD_IF2020-Trailer.mp4',
	}
	setVideo(newVideo) {
		console.log(newVideo)
		this.setState({ currentVideo: newVideo })
	}
	render() {
		return (
			<>
				<HeroSpace h2="" h1="Interactive Future 2021" subtitle="" alt="" />

				<div className={'stream-wrapper'}>
					<video
						controls
						title={'videoPlayer'}
						allowfullscreen
						src={
							'https://2020.if-exhibition.de/video/' + this.state.currentVideo
						}
					></video>
				</div>
				<TimeTable
					tables={ProgramData.dates}
					setVideo={(newVideo) => this.setVideo(newVideo)}
				/>
			</>
		)
	}
}

export default Program
