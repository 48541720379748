import axios from 'axios'
import elective from '../strapiExport/electives.json'

export const findAllElectives: any = async () => {
	return elective
}

export const findElectiveById: any = async (id: string) => {
	console.log(findObjectByField(elective, '_id', id))
	return [findObjectByField(elective, '_id', id)]
}

function findObjectByField(array: any, field: string, value: string) {
	for (let i = 0; i < array.length; i++) {
		if (array[i][field] === value) {
			return array[i]
		}
	}
}
