import React from "react";
import InfoCard from "./InfoCard/InfoCard";
import "../../assets/styles/variables.scss";
import "./SemesterTopicInfoCards.scss";
import Grid from "@material-ui/core/Grid";

const SemesterTopicInfoCards = props => {
	return (
		<div className="STIC_container">
			<Grid className="STIC_container_vertical-align" xs={12} md={6}>
				<InfoCard {...props.simulation} />
				<InfoCard {...props.ambient} />
			</Grid>
			<Grid className="STIC_container_vertical-align" xs={12} md={6}>
				<InfoCard {...props.iot} />
			</Grid>
		</div>
	);
};

export default SemesterTopicInfoCards;
