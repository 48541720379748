import React, { useEffect } from 'react'
import * as ProjectService from 'services/ProjectService.tsx'
import ProjectGrid from 'components/ProjectGallery/ProjectGallery'
import HeroSpace from 'components/global/HeroSpace/HeroSpace'
import Container from '@material-ui/core/Container'
import SemesterTopicInfoCards from 'components/SemesterTopicInfoCard/SemesterTopicInfoCards'

import projectImage from 'assets/svg/herospace/projekte-header.svg'

const Projects = () => {
	const [fetchData, setFetchData] = React.useState([])

	const getData = async () => {
		const res = await ProjectService.findAllProjects()
		setFetchData(res)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		getData()
	}, [])

	return (
		<div>
			<Container>
				<HeroSpace h1="projects" subline="line" year="2021" noTextBg image={projectImage} />
				<SemesterTopicInfoCards
					simulation={{
						theme: 'violett',
						headline: 'Simulation',
						paragraph:
							'Wozu braucht man Simulationen? In einer interaktiven Simulation kannst Du auch solche Prozesse aktiv und Schritt für Schritt nachvollziehen, die im echten Leben z.B. unsichtbar oder zu komplex für ein schnelles Durchdringen sind.',
					}}
					iot={{
						theme: 'rose',
						headline: 'Internet of Things',
						paragraph:
							'Wie leben wir im Internet der Dinge? Dass sich die Alltagsgegenstände unserer Umgebung untereinander vernetzen, um Informationen auszutauschen und uns ungefragt Dienstleistungen anzubieten, kann man sich heute noch kaum vorstellen. Wir haben es im vierten Semester schon einmal getan.',
					}}
					ambient={{
						theme: 'orange',
						headline: 'Ambient Intelligent Spaces',
						paragraph:
							'Was, wenn die Umgebung schlau wird? Dass greifbare Alltagsobjekte smart werden, wird unter dem Thema IoT behandelt. Was aber, wenn der Computer ganz verschwindet und einfach nur unsere Lebensumgebung auf uns reagiert? Wir nennen das „Ambient Intelligent Spaces“, also smarte Lebensumgebungen.',
					}}
				/>
			</Container>
			
			<ProjectGrid projects={fetchData} siteName={'project'}></ProjectGrid>
		</div>
	)
}

export default Projects
