import React from 'react'
import './Topic.scss'
import Grid from '@material-ui/core/Grid'
import { useHistory, withRouter } from 'react-router-dom'

const Topic = (props) => {
	let containerDirection = props.imageLeft ? 'row' : 'row-reverse'
	const history = useHistory()
	// var win = window,
	// doc = document,
	// docElem = doc.documentElement,
	// body = doc.getElementsByTagName("body")[0],
	// width = win.innerWidth || docElem.clientWidth || body.clientWidth,
	// height = win.innerHeight || docElem.clientHeight || body.clientHeight;
	return (
		<div className={'Topic'} id={props.id ? props.id : ''}>
			<Grid
				container
				spacing={10}
				direction={containerDirection}
				justify="center"
				alignItems="center"
				style={{
					backgroundColor: props.backgroundColor
						? props.backgroundColor
						: 'transparent',
				}}
			>
				<Grid item xs={12} md={6} align="center">
					<img
						className={'Topic_image'}
						src={props.image}
						alt={props.altText}
						title={props.altText}
					/>
				</Grid>
				<Grid item xs={12} md={6} className={'Topic_spacing'}>
					<div className={'Topic_description'}>
						<div>
							<h2 class="text-yellow">{props.heading}</h2>
							<div class="bg-yellow line"></div>
							<h2 class="text-yellow">{props.subline}</h2>
							<p>{props.paragraph}</p>
							{props.button ? (
								props.button.externalUrl ? (
									<button
										justify="flex-end"
										className="medium bg-sky-blue sh-primary"
										onClick={() => window.open(props.button.externalUrl)}
									>
										{props.button.title}
									</button>
								) : (
									<button
										className="medium bg-sky-blue sh-primary"
										onClick={() => history.push(props.button.url)}
									>
										{props.button.title}
									</button>
								)
							) : null}
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	)
}

export default withRouter(Topic)
