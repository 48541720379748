import React from 'react'
import './ProjectGallery.scss'
import FlipCard from './FlipCard/FlipCard'
import { Container } from '@material-ui/core'

const ProjectGrid = (props) => {
	const projects = !props.projects
		? null
		: props.projects.map((project, index) => {
				let positionClass
				let rowClass
				let count = index

				switch (index % 2) {
					case 0:
						positionClass = 'column-left '
						count = Math.floor(count / 2) + 1

						rowClass = 'row' + count + '-left '
						break
					case 1:
						positionClass = 'column-center '
						count = Math.floor(count / 2) + 1
						rowClass = 'row' + count + '-center '
						break
					case 2:
						positionClass = 'column-right '
						count = Math.floor(count / 2) + 1
						rowClass = 'row' + count + '-right '
						break
					default:
						positionClass = ''
						rowClass = ''
				}
				let thumbnailUrl = (
					(((project || {}).projectThumbnail || {}).formats || {}).small || {}
				).url

				thumbnailUrl = thumbnailUrl
					? thumbnailUrl
					: project.projectThumbnail.url

				return (
					<FlipCard
						title={project.projectTitle}
						description={project.teaserText}
						projectId={`${props.siteName}/${project._id}`}
						positionClass={positionClass}
						rowClass={rowClass}
						thumbnailUrl={thumbnailUrl}
					/>
				)
		  })
	return (
		<Container fixed>
			<div class="project-grid">{projects}</div>
		</Container>
	)
}

export default ProjectGrid
