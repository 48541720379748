import React from 'react'
import './Emotes.scss'
import IcoMoon from 'react-icomoon'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'

//Emoji imports
import emojiAngry from 'assets/svg/emoji/angry_cat.svg'
import emojiSmile from 'assets/svg/emoji/happy_cat.svg'
import emojiSad from 'assets/svg/emoji/sad_cat.svg'
import emojiAmazed from 'assets/svg/emoji/amazed_cat.svg'
import emojiClap from 'assets/svg/emoji/clap.svg'
import emojiDemon from 'assets/svg/emoji/imdaemon.svg'
import emojiLoL from 'assets/svg/emoji/laughing_cat.svg'
import emojiSour from 'assets/svg/emoji/sour_cat.svg'
import emojiThumb from 'assets/svg/emoji/thumb.svg'
import emojiHeart from 'assets/svg/emoji/heart_cat.svg'

class EmojiGrid extends React.Component {
	constructor(props) {
		super(props)

		this.emojis = [
			emojiSmile,
			emojiSad,
			emojiAngry,
			emojiAmazed,
			emojiClap,
			emojiDemon,
			emojiLoL,
			emojiSour,
			emojiHeart,
			emojiThumb,
		]
		this.pushIntoChat = this.pushIntoChat.bind(this)
	}

	render() {
		return (
			<div className="emoji-grid">
				{this.emojis.map((emoji, index) => (
					<i
						style={{ backgroundImage: `url(${emoji})` }}
						className="emoji"
						id={index + '_emoji'}
						onClick={() => {
							this.pushIntoChat(index)
						}}
					></i>
				))}
			</div>
		)
	}

	pushIntoChat(index) {
		let emoteID = index
		let ligatur = ''
		switch (emoteID) {
			case 0:
				ligatur = ':smile:'
				break
			case 1:
				ligatur = ':sad:'
				break
			case 2:
				ligatur = ':angry:'
				break
			case 3:
				ligatur = ':amazed:'
				break
			case 4:
				ligatur = ':clap:'
				break
			case 5:
				ligatur = ':demon:'
				break
			case 6:
				ligatur = ':lol:'
				break
			case 7:
				ligatur = ':sour:'
				break
			case 8:
				ligatur = ':heart:'
				break
			case 9:
				ligatur = ':thumb:'
				break
		}
		this.props.addEmoteToMessage(ligatur)
	}
}

export default EmojiGrid
