import React from 'react'
import './Navbar.scss'
import { NavLink, withRouter } from 'react-router-dom'
import ifLogoIcon from 'assets/svg/if-logo.svg'
import { SocialFoot } from 'components/global/Footer/Footer.jsx'

const NavigationButton = (props) => {
	// return (
	// 	<button
	// 		className={props.className + ' burger-menu'}
	// 		onClick={props.toggleMenu}
	// 	></button>

	// )
	return (
		<div
			className={props.className + ' burger-menu'}
			onClick={props.toggleMenu}
		>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	)
}

const NavItems = (props) => {
	//const allItems = document.getElementsByClassName('if-navbar_container_item')
	//const history = useHistory()
	return (
		<div className={props.className}>
			{/* <ul> */}
			{/*<Link to="/ifExhibition" className="if-navbar_container_item">
        Startseite
      </Link>*/}
			{/* <NavLink
				to="/home"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Startseite</li>
			</NavLink> */}
			<NavLink
				to="/home"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Die IF</li>
			</NavLink>
			{/* <NavLink
				to="/stage"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Stage</li>
			</NavLink> */}
			<NavLink
				to="/projects"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Projekte</li>
			</NavLink>
			<NavLink
				to="/electives"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Electives</li>
			</NavLink>
			{/* <NavLink
				to="/program"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Programm</li>
			</NavLink> */}
			<NavLink
				to="/speakers"
				className="if-navbar_container_item"
				onClick={props.toggleMenu}
			>
				<li>Speakers</li>
			</NavLink>

			<br />
			<br />

			<NavLink
				to="/legal-notice"
				className="if-navbar_container_item mobile"
				onClick={props.toggleMenu}
			>
				<li>Impressum</li>
			</NavLink>
			<NavLink
				to="/contact"
				className="if-navbar_container_item mobile"
				onClick={props.toggleMenu}
			>
				<li>Kontakt</li>
			</NavLink>
			<NavLink
				to="/privacy"
				className="if-navbar_container_item mobile"
				onClick={props.toggleMenu}
			>
				<li>Datenschutz</li>
			</NavLink>
		</div>
	)
}

class Navbar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			expanded: false,
		}
	}

	toggleMenu() {
		this.setState({ expanded: !this.state.expanded }, () => {
			if (this.state.expanded) {
				document.body.classList.add('noscroll')
			} else {
				document.body.classList.remove('noscroll')
			}
		})
	}

	render() {
		const burgerOpen = this.state.expanded ? ' open' : ''
		const navRootExpanded = this.state.expanded ? 'expanded' : ''
		const navItemsHidden_Desktop = this.state.expanded ? ' hide' : ''
		const navItemsHidden_Mobile = !this.state.expanded ? ' hide' : ''
		return (
			<div className={'navigation ' + navRootExpanded}>
				<div className="if-navbar_container">
					<NavLink
						to="/home"
						className="if-navbar_container_item"
						activeClassName="logoActive"
						onClick={() => this.state.expanded && this.toggleMenu()}
					>
						<div
							className="if-navbar_container_logo"
							style={{ backgroundImage: `url(${ifLogoIcon})` }}
						></div>
					</NavLink>
					<NavItems
						className={'if-navbar_container_links ' + navItemsHidden_Desktop}
						toggleMenu={() => {}}
					/>

					<NavigationButton
						className={'if-navbar_container_item mobile ' + burgerOpen}
						toggleMenu={() => this.toggleMenu()}
						inverted={navRootExpanded}
					/>
				</div>
				<NavItems
					className={'if-navbar_link_list' + navItemsHidden_Mobile}
					toggleMenu={() => this.toggleMenu()}
				/>
				<div className={'social-foot-wrapper mobile' + navItemsHidden_Mobile}>
					<SocialFoot />
				</div>
			</div>
		)
	}
}

export default withRouter(Navbar)
