import React, { useEffect } from 'react'
import HeroSpace from 'components/global/HeroSpace/HeroSpace'
import VideoGallery from 'components/VideoGallery/VideoGallery'
import ImageGallery from 'components/ImageGallery/ImageGallery'
import StudentCards from 'components/StudentCards/StudentCards'
import MultiTopic from 'components/MultiTopic/MultiTopic'
import * as ElectivesService from 'services/ElectivesService.tsx'
import Grid from '@material-ui/core/Grid'

import { useLocation } from 'react-router-dom'
import './Elective.scss'
import './ElectiveRow.scss'
import { Container } from '@material-ui/core'

const Elective = (props) => {
	const [oneElectiveData, setOneElectiveData] = React.useState([])

	const { pathname } = useLocation()

	const URLseparated = pathname.split('/')
	const URLelectiveID = URLseparated[URLseparated.length - 1]

	const getData = async () => {
		const res_single = await ElectivesService.findElectiveById(URLelectiveID)
		setOneElectiveData(res_single)
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<div>
			{oneElectiveData.map((project) => (
				<div>
					<HeroSpace
						bgCover
						image={`/strapiExport${project.projectBanner.url}`}
						h1={project.projectTitle}
						noTextBg
						subline="longline"
					/>
					<Container fixed>
						<Grid container>
							<Grid item xs={12}>
								<MultiTopic
									topics={[
										{
											heading: project.headline,
											paragraph: project.paragraph,
											image: `/strapiExport${project.projectThumbnail.url}`,
										},
									]}
								></MultiTopic>
							</Grid>
						</Grid>
						<div className="electivebg">
							<StudentCards people={project.teamMembers} />
						</div>

						<Grid container direction={'row-reverse'}>
							<Grid item xs={12} sm={7}>
								{project.mainVideo ? (
									<VideoGallery
										videos={[{ url: project.url, title: '' }]}
									></VideoGallery>
								) : (
									<img
									// src={`/strapiExport${project.projectThumbnail.url}`}
									// alt={`/strapiExport${project.projectThumbnail.alternativeText}`}
									// className={'electiveMainImage'}
									/>
								)}
							</Grid>
						</Grid>
						{project.images ? <ImageGallery images={project.images} /> : null}
					</Container>
				</div>
			))}
		</div>
	)
}

export default Elective
