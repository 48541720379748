import React, { useEffect, useRef } from 'react'
// import Iframe from 'react-iframe-click'
import './Stage.scss'
import RunningProgramInfo from 'components/RunningProgramInfo/RunningProgramInfo'
import Chat from 'components/Chat/Chat'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import * as ProjectService from 'services/ProjectService.tsx'
import { useHistory, withRouter } from 'react-router-dom'
import * as LiveStreamInfoService from 'services/LivestreamInfosService'
import * as ElectivesService from 'services/ElectivesService'
import HeroSpace from 'components/global/HeroSpace/HeroSpace.jsx'
import Iframe from 'react-iframe-click'
import io from 'socket.io-client'

const Stage = (props) => {
	const history = useHistory()
	const [oneProjectData, setOneProjectData] = React.useState([])

	const [liveStreamInfo, setLiveStreamInfo] = React.useState([])
	const [lastFokusInputField, setLastFokusInputField] = React.useState(true)

	let lastFokusInputField2 = true
	// let chatRef = React.createRef()
	var res_single
	const getData = async () => {
		const res_info = await LiveStreamInfoService.findLiveStreamInfos()
		setLiveStreamInfo(res_info)

		if (liveStreamInfo[0]) {
			switch (liveStreamInfo[0].EventType) {
				case 'project':
					res_single = await ProjectService.findProjectById(
						liveStreamInfo[0].projectId
					)
					setOneProjectData(res_single)
					break
				case 'elective':
					res_single = await ElectivesService.findElectiveById(
						liveStreamInfo[0].projectId
					)
					setOneProjectData(res_single)
					break
			}
		}
	}
	let chatUrl = 'https://test4.if-exhibition.de'
	// chatUrl = 'localhost:4224'
	let chatSocket = io(chatUrl, {
		transports: ['websocket'],
		secure: true,
		autoConnect: false,
	})
	let phaserURL = 'https://test3.if-exhibition.de'
	// phaserURL = 'localhost:8080'
	let phaserSocket = io(phaserURL, {
		transports: ['websocket'],
		secure: true,
		autoConnect: false,
	})

	useEffect(() => {
		// window.scrollTo(0, 0);
		// getData();
		// let iframe = document.getElementById('phaserGame')
		// let body = iframe.contentWindow.document.getElementsByTagName('body')[0]
		// body.addEventListener('click', function () {
		// 	console.log('weii')
		// })
		// console.log(body)

		const interval = setInterval(() => {
			getData()
		}, 30000)

		return () => clearInterval(interval)
	}, [getData])

	let phaserGameClicked = () => {
		// console.log(chatRef)
		// if (chatRef.current != null) {
		// 	chatRef.current.addEmoteToMessage()
		// }
		// lastFokusInputField = false
		setLastFokusInputField(false)
	}

	const onInputClick = () => {
		// lastFokusInputField = true
		console.log(setLastFokusInputField(true))
	}

	let runningProgrammInfo_headline = 'if-Ausstellung'
	let runningProgrammInfo_paragraph =
		'Werkschau des Studiengangs Interactive Media Design'
	let runningProgrammInfo_projectId = '/program'
	let runningProgrammInfo_buttonDes = 'Zum Programm'

	if (liveStreamInfo && liveStreamInfo[0]) {
		if (liveStreamInfo[0].EventType === 'free') {
			runningProgrammInfo_headline = liveStreamInfo[0].Title
			runningProgrammInfo_paragraph = liveStreamInfo[0].description
		}
	}
	if (oneProjectData && oneProjectData[0]) {
		switch (liveStreamInfo[0].EventType) {
			case 'project':
				runningProgrammInfo_headline = oneProjectData[0].projectTitle
				runningProgrammInfo_paragraph = oneProjectData[0].description
				runningProgrammInfo_projectId = '/project/' + oneProjectData[0].id
				runningProgrammInfo_buttonDes = 'Zur Projektseite'
				break
			case 'elective':
				runningProgrammInfo_headline = oneProjectData[0].projectTitle
				runningProgrammInfo_paragraph = oneProjectData[0].paragraph
				runningProgrammInfo_projectId = '/elective/' + oneProjectData[0].id
				runningProgrammInfo_buttonDes = 'Zur Electiveseite'
				break
		}
	}

	return (
		<div className={'page-stage'}>
			<Container>
				<div class="headline">
					<h2 class="text-yellow hl-yellow">Hier geht die </h2>
					<div class="bg-yellow line"></div>
					<h2 class="text-yellow hl-yellow">Party ab!</h2>
				</div>
				<div className="header">
					<Grid className="grid-container" container spacing={0} d>
						<Grid item xs={12} lg={9}>
							<div className={'stream-wrapper'}>
								{/* <iframe
							title={'stage-stream'}
							id={'stream'}
							src={
								'http://if-test.florian-beck.de/view/36487cc2-0dcb-4ccd-af1d-bb2b4b0bedc1/?embedded=True/?embedded=True&countViewers=False'
							}
						></iframe> */}
								<iframe
									src="https://vimeo.com/event/1147186/embed"
									width="640"
									height="400"
									frameborder="0"
									// id={'stream'}
									// title={'stage-stream'}
									allow="autoplay; fullscreen; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</Grid>

						<Grid item xs={12} lg={3}>
							<div className={'chat-wrapper'}>
								{
									<Chat
										onInputClick={onInputClick}
										socket={chatSocket}
										phaserSocket={phaserSocket}
										lastFokusInputField={lastFokusInputField}
									/>
								}
							</div>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} lg={12}>
						<div className="phaser-room">
							<Iframe
								src="https://test2.if-exhibition.de/"
								// src="http://localhost:3000/"
								title="phaser"
								id="phaserIframeWindow"
								className="frameboy"
								height="1000px"
								width="100%"
								onInferredClick={() => phaserGameClicked()}
							/>
						</div>
					</Grid>
				</Grid>
			</Container>

			{/*<div
				className={'running-program-info-wrapper'}
				style={{
					backgroundColor: 'rgba(106,87,206,0.5)',
				}}
			>
				<Container fixed>
					<RunningProgramInfo
						headline={runningProgrammInfo_headline}
						paragraph={runningProgrammInfo_paragraph}
						projectId={runningProgrammInfo_projectId}
						buttonDes={runningProgrammInfo_buttonDes}
					/>
				</Container>
			</div> }


			{/* <VideoGallery videos={videoMockData} /> */}
		</div>
	)
}

export default withRouter(Stage)
