import React, { useEffect } from 'react'
//import { makeStyles } from "@material-ui/core/styles";
import 'assets/styles/variables.scss'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import ProgramData from 'assets/program.json'
import { NavLink } from 'react-router-dom'
import TimeTable from 'components/TimeTable/TimeTable'
import CurrentProgram from 'components/CurrentProgramPoint/CurrentProgram'

import './Home.scss'

//Bilder
import homeImage from 'assets/svg/herospace/startseite_header.svg'
import robotImage from 'assets/svg/hand_touch.svg'
import brainImage from 'assets/svg/brain.svg'
import windowImage from 'assets/svg/hand.svg'

import msPc from 'assets/png/speaker/ms-pc.png'
import qundg from 'assets/png/speaker/qundg.png'
import simoneRebaudengo from 'assets/png/speaker/simone-rebaudengo.png'
import xeem from 'assets/png/speaker/xeem.png'
import gSjK from 'assets/png/speaker/gs-jk.png'

// import headerImg from 'assets/svg/heart.svg'
import headerImgXs from 'assets/svg/if-logo.svg'
import desktop from 'assets/svg/heart.svg'

//import PresentationDescription from "components/presentation-description/PresentationDescription";

//import "antd/dist/antd.css";
import HeroSpace from 'components/global/HeroSpace/HeroSpace.jsx'
import PeopleCards from 'components/PeopleCards/PeopleCards.jsx'
import Topic from 'components/MultiTopic/Topic/Topic.jsx'
import * as SpeakerService from 'services/SpeakerService.tsx'

import MultiTopic from 'components/MultiTopic/MultiTopic'
//Chat-Komponent
// import Dashboard from "components/chat/Dashboard";
// import Store from "components/chat/Store";

//VideoGallery-Komponent
//import VideoGallery from "components/videoGallery/VideoGallery"
const images = [simoneRebaudengo, xeem, qundg, msPc, gSjK]

const Home = () => {
	const [fetchSpeaker, setFetchSpeaker] = React.useState([])

	const getSpeaker = async () => {
		const res = await SpeakerService.findAllSpeakers()
		const speaker = []
		console.log(res)
		res.forEach((e) => {
			const sp = {
				name: e.speakerName,
				subline: e.speakerDescription,
				img: images[res.indexOf(e)],
				mail: e.speakerMail,
				link: `/speakers#${e.speakerID}`,
			}
			speaker.push(sp)
		})
		setFetchSpeaker(speaker)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		getSpeaker()
	}, [])
	return (
		<div class="home">
			<HeroSpace
				h1="Interactive Future Exhibition"
				noTextBg
				alt="React App"
				year="2021"
				image={homeImage}
				long
			/>
			<Container fixed>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<MultiTopic
							topics={[
								{
									heading: 'Roboter, Hologramme und Virtual ',
									subline: 'Reality – klingt nach Science-Fiction?',
									paragraph:
										'Für Studierende von Interactive Media Design gehören sie zum Arbeitsalltag! Mit der Interactive Future Exhibition (if) öffnet der Studiengang die Türen für die Öffentlichkeit und bietet mit täglichen Projektausstellungen, -präsentationen, Workshops und interessanten Beiträgen von weltweit bekannten Speakern Einblick in ihre Welt!',
									image: robotImage,
									imageLeft: false,
								},
							]}
						></MultiTopic>
					</Grid>
					<Grid item xs={12}>
						<CurrentProgram></CurrentProgram>
						<a href="/stage" style={{ textDecoration: 'none' }}>
							<button class="large bg-violett text-sky-blue box-shadow home-centered-button hover">
								Hier geht es zum Geschehen!
							</button>
						</a>

						{/* <video controls width="100%" height="auto">
							<source
								src="https://vimeo.com/manage/videos/576853081"
								type="video/mp4"
							/>
						</video> */}
						<div class="wrapper">
							<iframe
								src="https://player.vimeo.com/video/576853081?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								width="100%"
								height="360"
								frameborder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								title="IF 2021 - Showreel"
							></iframe>
						</div>
					</Grid>
					<TimeTable tables={ProgramData.dates} />
					{/* <Grid item xs={12} sm={5}>
						<NavLink
							to={'program'}
							onClick={() => {
								window.scrollTo(0, 0)
							}}
							style={{ textDecoration: 'none' }}
						>
							<button className="medium bg-sky-blue sh-primary home-centered-button">
								Zur Videoaufzeichnung
							</button>
						</NavLink>
					</Grid> */}

					<MultiTopic
						topics={[
							{
								heading: 'Die interdisziplinären Semesterprojekte',
								subline: 'Das Herzstück des Studiums',
								paragraph:
									'Wir gestalten alle Interaktionen, doch wie sehen diese in den Semestern aus? Egal ob Simulationen, Internet of Things oder Ambient Intelligent Spaces! All unsere Projekte sind mit Herzblut entstanden und sind bereit, der Welt präsentiert zu werden.',
								image: windowImage,
							},
							{
								heading: 'Interactive Media Design',
								subline: 'Der Studiengang der Zukunft',
								paragraph:
									'Das Studium ist praxisorientiert. In jedem Semester werden in kleinen Teams Projekte durchgeführt. Die Studieninhalte werden von einem interdisziplinären Betreuerteam direkt in den Projektteams vermittelt. Die Projekte haben häufig einen direkten Marktbezug oder werden gemeinsam mit einem Unternehmen durchgeführt. Projektpartner sind z.B. Honda, Opel, Städel-Museum, ZDF, Siemens oder Telekom.',
								image: brainImage,
							},
						]}
					></MultiTopic>

					<Grid item xs={0} sm={5}></Grid>
					<Grid item xs={12}>
						<PeopleCards
							button={{
								text: 'Erfahre mehr über die Speaker',
								link: '/speakers',
							}}
							people={fetchSpeaker}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	)
}

export default Home
