import React from 'react'
import '../StudentCards.scss'

const StudentCard = (props) => {
	return (
		<div className="peopleCard_container box-shadow hover">
			<img
				src={props.img ? `/strapiExport${props.img.url}` : null}
				alt={'Student: ' + props.name}
				title={props.name}
			/>
			<div className="studentCard_container_text">
				<h3 className="studentCard_container_text_name">{props.name}</h3>
			</div>
			<div className="studentCard_container_back hover">
				<p className="studentCard_container_text_subline">{props.subline}</p>
				{props.mail && (
					<a
						className="studentCard_container_text_mail"
						href={'mailto:' + props.mail}
					>
						E-Mail senden
					</a>
				)}
			</div>
		</div>
	)
}

export default StudentCard
