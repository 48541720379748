import React, { useEffect } from 'react'
import * as ElectivesService from 'services/ElectivesService.tsx'
import ProjectGrid from 'components/ProjectGallery/ProjectGallery'
import HeroSpace from 'components/global/HeroSpace/HeroSpace'
import Container from '@material-ui/core/Container'

//Bilder
import electiveImage from 'assets/svg/herospace/elective_header.svg'

const Electives = () => {
	const [fetchData, setFetchData] = React.useState([])

	const getData = async () => {
		const res = await ElectivesService.findAllElectives()
		setFetchData(res)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		getData()
	}, [])

	return (
		<div>
			<Container>
				<HeroSpace
					
					h1="electives"
					subline="line"
					year="2021"
					noTextBg
					image={electiveImage}
				/>
			</Container>
			<ProjectGrid projects={fetchData} siteName={'elective'}></ProjectGrid>
		</div>
	)
}

export default Electives
