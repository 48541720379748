import axios from 'axios'
import speaker from '../strapiExport/speakers.json'
export const findAllSpeakers: any = async () => {
	return speaker
}

export const findSpeakerById: any = async (id: string) => {
	return findObjectByField(speaker, 'id', id)
}

function findObjectByField(array: any, field: string, value: string) {
	for (let i = 0; i < array.length; i++) {
		if (array[i][field] === value) {
			return array[i]
		}
	}
}
