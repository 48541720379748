import React from "react";
import "./TimeTableCard.scss";

const Row = props => {
	return (
		<div className="timeTable-row">
			<p className="timeTable-row-time"> {props.time} </p>
			<p className="text-bold timeTable-row-eventName">{props.eventName}</p>
			{props.video ? (
				<button onclick={() => props.playVideo(props.video)} />
			) : null}
		</div>
	);
};

const TimeTableCard = props => {
	let rows = props.events.map(event =>
		event.video ? (
			<div className={"videoLink-wrapper"}>
				{event.video.map(video => (
					<button
						className={"medium program-video"}
						onClick={() => props.setVideo(video)}
					>
						{video.replace('.mp4', '')}
					</button>
				))}
			</div>
		) : (
			<li key={event.time} className={"program-row"}>
				{Row({
					time: event.time,
					eventName: event.name,
					video: props.video,
					playVideo: props.playVideo
				})}
			</li>
		)
	);
	return (
		<div className="timeTable box-shadow">
			<div className="timeTable-topOrder">
				<h3 className="timeTable-date text-nearly-black">{props.date}</h3>
				<div className="timeTable-bar"></div>
			</div>
			<ul className="timeTable-eventList">{rows}</ul>
		</div>
	);
};

export default TimeTableCard;
