import React from 'react'
import './StudentCards.scss'
import Grid from '@material-ui/core/Grid'
import StudentCard from './StudentCard/StudentCard'

const StudentCards = (props) => {
	return (
		<div>
			<div className="studentCards_container">
				{Array.isArray(props.people)
					? props.people.map((person) => (
							<StudentCard
								name={person.name}
								subline={person.about}
								mail={person.email}
								img={person.img}
							></StudentCard>
					  ))
					: null}
			</div>
		</div>
	)
}

export default StudentCards
