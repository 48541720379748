import React from 'react'
import './CurrentProgram.scss'
import 'assets/styles/variables.scss'
import Container from '@material-ui/core/Container'
import ProgramData from 'assets/program.json'
import moment from 'moment'
import programSvg from 'assets/svg/programm.svg'

class CurrentProgram extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			curEvent: {},
			interval: 0,
		}
	}

	getCurrentProgram() {
		console.log('Hey :>')
		let events = []
		let curEvent = {}

		moment.locale('de')

		for (const day of ProgramData.dates) {
			let date = moment(day.date, 'Do MMMM')
			if (date.isBefore(moment())) {
				events = day.events
				break
			}
		}

		for (const event of events) {
			let time = event.time.split('–')
			let startTime = moment(time[0], 'hh:mm')
			let endTime = moment(time[1], 'hh:mm')

			if (startTime.isSameOrBefore(moment()) && endTime.isAfter(moment())) {
				curEvent = event
				break
			}
		}

		console.log(curEvent)
		if (Object.keys(curEvent).length === 0) {
			curEvent = { name: 'Momentan läuft kein Programm' }
		}
		this.setState({ curEvent })
	}

	componentDidMount() {
		this.getCurrentProgram()
		this.setState({
			interval: setInterval(() => {
				this.getCurrentProgram()
			}, 60000),
		})

		console.log(this.state.curEvent.name)
	}

	componentWillUnmount() {
		clearInterval(this.state.interval)
	}
	render() {
		return (
			<Container>
				<img className=" program-image" src={programSvg} />
				<span className="program-text text-sky-blue">
					{this.state.curEvent.name}
				</span>
			</Container>
		)
	}
}

export default CurrentProgram
