import React from 'react'
import './HeroSpace.scss'
import Container from '@material-ui/core/Container'

const HeroSpace = (props) => {
	let right = ''
	props.textRight ? (right = 'if-hero-space_right') : (right = '')

	let noTextBg = ''
	props.noTextBg
		? (noTextBg = 'if-hero-space_background_no')
		: (noTextBg = 'if-hero-space_background_dark')

	let visibleH2 = ''
	props.h2 === 'noText'
		? (visibleH2 = ' if-hero-space_transparent')
		: (visibleH2 = '')

	let bgCover = ''
	props.bgCover 
	? (bgCover = 'cover')
	:  (bgCover = '')

	let projectColor = ''
	props.noTextBg ? (projectColor = '') : (projectColor = 'text-white')

	let subline = ''
	switch (props.subline) {
		case 'line':
			subline = 'if-hero-space_subline'
			break
		case 'longline':
			subline = 'if-hero-space_subline_long'
			break
		case '':
			subline = ''
			break
	}

	let year = ''
	props.year === 'noText' ? (year = 'if-hero-space_transparent') : (year = '')

	let yearRight = ''
	props.h1 === 'Interactive Future Exhibition'
		? (yearRight = 'if-hero-space_year_left')
		: (yearRight = 'if-hero-space_year_right')

	let longTitle = ''
	props.long ? (longTitle = 'if-hero-space_text_long') : (longTitle = '')

	let projectBg = ''
	props.image ? (projectBg = 'bg-navi-blue') : (projectBg = '')

	return (
		<div className="if-hero-space_root">
			<div
				className={`if-hero-space_image if-hero-space_desktopOnly ${projectBg} ${bgCover}`}
				style={{ backgroundImage: `url(${props.image})` }}
			></div>

			<div
				className={`if-hero-space_image if-hero-space_mobileOnly ${projectBg}`}
				{...(props.xsImage
					? {
							style: {
								backgroundImage: `url(${props.xsImage})`,
							},
					  }
					: {
							style: {
								backgroundImage: `url(${props.image})`,
							},
					  })}
			></div>
			<Container className="if-hero-space_container">
				<div className={`if-hero-space_text ${noTextBg} ${right} ${longTitle}`}>
					<h2 className={'if-hero-space_text_h2' + visibleH2}>{props.h2}</h2>
					<div className={`if-hero-space_text_h1 ${projectColor}`}>
						{props.h1}&nbsp;
						<p className={` ${subline}`}></p>
						<h2 className={`text-lavender ${yearRight}${year}`}>
							{props.year}
						</h2>
					</div>
					<p className="subtitle text-small">{props.subtitle}</p>
				</div>
			</Container>
		</div>
	)
}

export default HeroSpace
